<template>
  <v-container class="container-content-base">
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column class="px-2">
      <div class="d-flex">
        <v-layout column>
          <span class="text-uppercase text--body-5-20">{{ detail.name }}</span>
          <span class="font-italic color-gray text--body-4-12">
            {{ $t("registed_date") }}: {{ formatDate(detail.createdAt) }}
          </span>
          <span class="font-italic color-gray text--body-4-12">
            {{ $t("post_offices") }}: {{ detail.poName }}
          </span>
        </v-layout>
        <v-layout justify-end>
          <v-btn disabled color="#FFEDD2" style="cursor: default">
            <span style="color: #FFAF2E">{{ getTextCustomerType(detail.customerType) }}</span>
          </v-btn>
        </v-layout>
      </div>
      <div class="d-flex">
        <v-layout v-if="detail.saleName !== null" column style="flex: 50">
          <span v-if="detail.saleName" class="mt-3 text--body-4-18">
            {{ $t("staff_in_charge") }}
          </span>
          <v-layout v-if="detail.saleId" column>
            <span class="text-uppercase text--body-5-14">{{ detail.postOfficeName }}</span>
            <span class="text-uppercase text--body-5-14">{{ detail.saleName }} {{ getTextCode(detail) }}</span>
            <span class="text--body-4-12" style="color:#8E8EA1">{{ detail.salePhone }}</span>
            <span class="text--body-4-12" style="color:#8E8EA1">{{ detail.saleEmail }}</span>
          </v-layout>
        </v-layout>
        <v-layout justify-end style="align-items: center">
          <v-btn
            v-if="checkRoleBtn"
            color="primary"
            @click="showDialogCustomer"
          >
            {{ $t("Choice-staff") }}
          </v-btn>
        </v-layout>
      </div>
      <span class="text-uppercase color-primary text--body-5-14 py-2">
        {{ $t("general_info") }}
      </span>
      <div class="border-primary br-10 px-8 py-3">
        <v-row>
          <v-col cols="6">
            <v-layout column>
              <span class="text--body-4-14">{{ $t("phone") }}</span>
              <span class="text--body-5-14 pa-2">{{ detail.phone }}</span>
              <span class="text--body-4-14">{{ $t("email") }}</span>
              <span class="text--body-5-14 pa-2">{{ detail.email }}</span>
              <span class="text--body-4-14">{{ $t("address") }}</span>
              <span class="text--body-5-14 pa-2">
                {{ detail.provinceName }}
              </span>
              <span class="text--body-4-14">{{
                $t("number_orders_per_month")
              }}</span>
              <span class="text--body-5-14 pa-2">
                {{ detail.commitedOutput }}
              </span>
              <span class="text--body-4-14">{{ $t("referral_code") }}</span>
              <span class="text--body-5-14 pa-2">
                {{ detail.referCode }}
              </span>
            </v-layout>
          </v-col>
          <v-col cols="6">
            <v-layout column>
              <span class="text--body-4-14">{{ $t("bank_name") }}</span>
              <span class="text--body-5-14 pa-2">{{ detail.bankName }}</span>
              <span class="text--body-4-14">{{ $t("branch_name") }}</span>
              <span class="text--body-5-14 pa-2">{{ detail.bankBranch }}</span>
              <span class="text--body-4-14">{{ $t("account_name") }}</span>
              <span class="text--body-5-14 pa-2">
                {{ detail.bankAccountName }}
              </span>
              <span class="text--body-4-14">{{ $t("account_number") }}</span>
              <span class="text--body-5-14 pa-2">{{ detail.bankAccount }}</span>
            </v-layout>
          </v-col>
        </v-row>
      </div>
      <v-form class="mt-2" @submit.prevent="submitForm">
        <!--ninja van-->
        <div>
          <span class="text-uppercase color-primary text--body-5-14 pt-4 pb-2">
            {{ $t("establish-njv") }}
          </span>
          <div class="border-primary br-10 px-8 py-3 mb-3">
            <v-row>
              <v-col cols="6">
                <v-layout column>
                  <div>
                    <span class="text--body-4-14">
                      {{ $t("ship_service") }}
                    </span>
                  </div>
                  <v-text-field
                    outlined
                    disabled
                    filled
                    hide-details
                    :value="$t('normal')"
                    class="field-partner"
                  />
                </v-layout>
              </v-col>
              <v-col cols="6">
                <v-layout column>
                  <div class="d-flex autocomplete-price">
                    <span class="text--body-4-14">
                      {{ $t("shop_price_list") }}
                    </span>
                  </div>
                  <v-text-field
                    v-if="!checkRoleBtn"
                    v-model="valuePriceNjvName"
                    outlined
                    disabled
                    filled
                    hide-details
                    :value="$t('normal')"
                    class="field-partner"
                  />
                  <validation-provider v-else>
                    <v-select
                      v-model="valuePriceNjv"
                      outlined
                      :disabled="!checkRoleBtn"
                      dense
                      class="rounded-lg"
                      item-text="name"
                      item-value="id"
                      :items="lstPriceNjv"
                      :no-data-text="$t('no-data')"
                      :placeholder="$t('place_holders.shop_price_list')"
                    >
                      <template v-slot:selection="data">
                        <div class="select-item flex-column d-flex">
                          <div class="title-item">
                            {{ data.item.name }}
                            <span
                              v-if="data.item.isDefault === 1"
                              class="default-price"
                            >
                              [{{ $t("default") }}]
                            </span>
                          </div>
                        </div>
                      </template>
                      <template v-slot:item="item">
                        <div class="title-item">
                          {{ item.item.name }}
                          <span
                            v-if="item.item.isDefault === 1"
                            class="default-price"
                          >
                            [{{ $t("default") }}]
                          </span>
                        </div>
                      </template>
                    </v-select>
                  </validation-provider>
                </v-layout>
              </v-col>
            </v-row>
          </div>
          <!--ghtk-->
          <span class="text-uppercase color-primary text--body-5-14 pt-4 pb-2">
            {{ $t("establish-ghtk") }}
          </span>
          <div class="border-primary br-10 px-8 py-3">
            <!--tieu chuan-->
            <v-row>
              <v-col cols="6">
                <v-layout column>
                  <div>
                    <span class="text--body-4-14">
                      {{ $t("ship_service") }}
                    </span>
                  </div>
                  <v-text-field
                    outlined
                    disabled
                    filled
                    hide-details
                    :value="$t('normal')"
                    class="field-partner"
                  />
                </v-layout>
              </v-col>
              <v-col cols="6">
                <v-layout column>
                  <div class="d-flex autocomplete-price">
                    <span class="text--body-4-14">
                      {{ $t("shop_price_list") }}
                    </span>
                  </div>
                  <v-text-field
                    v-if="!checkRoleBtn"
                    v-model="valuePriceGhtkNorName"
                    outlined
                    disabled
                    filled
                    hide-details
                    :value="$t('normal')"
                    class="field-partner"
                  />
                  <validation-provider v-else>
                    <v-select
                      v-model="valuePriceGhtkNor"
                      outlined
                      dense
                      hide-details
                      :disabled="!checkRoleBtn"
                      class="rounded-lg"
                      item-text="name"
                      item-value="id"
                      :items="lstPriceGhtkNormal"
                      :no-data-text="$t('no-data')"
                      :placeholder="$t('place_holders.shop_price_list')"
                    >
                      <template v-slot:selection="data">
                        <div class="select-item flex-column d-flex">
                          <div class="title-item">
                            {{ data.item.name }}
                            <span
                              v-if="data.item.isDefault === 1"
                              class="default-price"
                            >
                              [{{ $t("default") }}]
                            </span>
                          </div>
                        </div>
                      </template>
                      <template v-slot:item="item">
                        <div class="title-item">
                          {{ item.item.name }}
                          <span
                            v-if="item.item.isDefault === 1"
                            class="default-price"
                          >
                            [{{ $t("default") }}]
                          </span>
                        </div>
                      </template>
                    </v-select>
                  </validation-provider>
                </v-layout>
              </v-col>
            </v-row>
            <!--Nhanh-->
            <v-row>
              <v-col cols="6">
                <v-layout column>
                  <div>
                    <span class="text--body-4-14">
                      {{ $t("ship_service") }}
                    </span>
                  </div>
                  <v-text-field
                    outlined
                    disabled
                    filled
                    hide-details
                    :value="$t('fast')"
                    class="field-partner"
                  />
                </v-layout>
              </v-col>
              <v-col cols="6">
                <v-layout column>
                  <div class="d-flex autocomplete-price">
                    <span class="text--body-4-14">
                      {{ $t("shop_price_list") }}
                    </span>
                  </div>
                  <v-text-field
                    v-if="!checkRoleBtn"
                    v-model="valuePriceGhtkExpName"
                    outlined
                    disabled
                    filled
                    hide-details
                    :value="$t('normal')"
                    class="field-partner"
                  />
                  <validation-provider v-else>
                    <v-select
                      v-model="valuePriceGhtkExp"
                      outlined
                      dense
                      hide-details
                      :disabled="!checkRoleBtn"
                      class="rounded-lg"
                      item-text="name"
                      item-value="id"
                      :items="lstPriceGhtkExpress"
                      :no-data-text="$t('no-data')"
                      :placeholder="$t('place_holders.shop_price_list')"
                    >
                      <template v-slot:selection="data">
                        <div class="select-item flex-column d-flex">
                          <div class="title-item">
                            {{ data.item.name }}
                            <span
                              v-if="data.item.isDefault === 1"
                              class="default-price"
                            >
                              [{{ $t("default") }}]
                            </span>
                          </div>
                        </div>
                      </template>
                      <template v-slot:item="item">
                        <div class="title-item">
                          {{ item.item.name }}
                          <span
                            v-if="item.item.isDefault === 1"
                            class="default-price"
                          >
                            [{{ $t("default") }}]
                          </span>
                        </div>
                      </template>
                    </v-select>
                  </validation-provider>
                </v-layout>
              </v-col>
            </v-row>
            <!--hang qua kho-->
            <v-row>
              <v-col cols="6">
                <v-layout column>
                  <div>
                    <span class="text--body-4-14">
                      {{ $t("ship_service") }}
                    </span>
                  </div>
                  <v-text-field
                    outlined
                    disabled
                    filled
                    hide-details
                    :value="$t('over')"
                    class="field-partner"
                  />
                </v-layout>
              </v-col>
              <v-col cols="6">
                <v-layout column>
                  <div class="d-flex autocomplete-price">
                    <span class="text--body-4-14">
                      {{ $t("shop_price_list") }}
                    </span>
                  </div>
                  <v-text-field
                    v-if="!checkRoleBtn"
                    v-model="valuePriceGhtkOvrName"
                    outlined
                    disabled
                    filled
                    hide-details
                    :value="$t('normal')"
                    class="field-partner"
                  />
                  <validation-provider v-else>
                    <v-select
                      v-model="valuePriceGhtkOvr"
                      outlined
                      dense
                      hide-details
                      :disabled="!checkRoleBtn"
                      class="rounded-lg"
                      item-text="name"
                      item-value="id"
                      :items="lstPriceGhtkOver"
                      :no-data-text="$t('no-data')"
                      :placeholder="$t('place_holders.shop_price_list')"
                    >
                      <template v-slot:selection="data">
                        <div class="select-item flex-column d-flex">
                          <div class="title-item">
                            {{ data.item.name }}
                            <span
                              v-if="data.item.isDefault === 1"
                              class="default-price"
                            >
                              [{{ $t("default") }}]
                            </span>
                          </div>
                        </div>
                      </template>
                      <template v-slot:item="item">
                        <div class="title-item">
                          {{ item.item.name }}
                          <span
                            v-if="item.item.isDefault === 1"
                            class="default-price"
                          >
                            [{{ $t("default") }}]
                          </span>
                        </div>
                      </template>
                    </v-select>
                  </validation-provider>
                </v-layout>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-layout justify-end class="mt-3">
              <v-btn
                v-if="detail.status !== constants.statusCustomer.off && checkRoleLock"
                class="rounded-lg mr-3"
                @click="lockAccount"
              >
                {{ $t("lock") }}
              </v-btn>
              <v-btn
                v-if="checkRoleBtn"
                color="primary"
                type="submit"
                class="rounded-lg"
              >
                {{ $t("save") }}
              </v-btn>
            </v-layout>
          </div>
        </div>
      </v-form>
      <!-- Dialog loading -->
      <dialog-loading v-model="showLoading" :message="$t('processing')" />

      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :icon-src="getIcon"
        :message="message"
      />

      <DialogCustomer
        ref="showDialogCustomer"
        :show-dialog="isShowDialogCustomer"
        :title="'Chọn người phụ trách'"
        @close-dia-log="closeDialogCustomer"
        @select-employee="selectEmployee"
      />

      <dialog-confirm
        v-model="showDialogSave"
        :icon-src="getIcon"
        :message="message"
        :text-cancel="textCancel"
        :text-accept="textAccept"
        :on-cancel="cancelDialog"
        :on-accept="onSubmit"
      />
      <!--khoa account-->
      <dialog-confirm
        v-model="showDialogLock"
        :icon-src="getIcon"
        :message="message"
        :text-cancel="textCancel"
        :text-accept="textAccept"
        :on-cancel="cancelDialog"
        :on-accept="onAccept"
      />
    </v-layout>
  </v-container>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogCustomer from "@/views/customer/components/DialogCustomer"
import DialogConfirm from '@/components/dialog/DialogConfirm.vue'
import { DataBreadCrumbUtils } from "@/helpers/dataBreadCrumbUtils";
import { mapActions } from "vuex";
import constants from "@/constants";
import routePaths from "@/router/routePaths";
import { UserService } from "@/services/userService";
import { PricesService } from "@/services/pricesService";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { StringUtils } from "@/helpers/stringUtils";
// import { StorageService } from '@/services/storageService'

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogCustomer,
    DialogConfirm,
    Breadscrumbs
  },
  data() {
    return {
      constants,
      id: this.$route.query.id,
      currentUser: JSON.parse(localStorage.getItem("CURRENT_USER")),
      showLoading: false,
      requireRules: {
        required: true,
      },
      detail: [],
      listPriceGroup: [],
      listSale: [],
      lstPriceNjv: [],
      lstPriceGhtkNormal: [],
      lstPriceGhtkExpress: [],
      lstPriceGhtkOver: [],
      timeout: null,
      normalService: 'Tiêu chuẩn',
      // notifications
      showNoti: false,
      valuePriceNjv: null,
      valuePriceGhtkNor: null,
      valuePriceGhtkExp: null,
      valuePriceGhtkOvr: null,
      valuePriceNjvName: null,
      valuePriceGhtkNorName: null,
      valuePriceGhtkExpName: null,
      valuePriceGhtkOvrName: null,
      typeNoti: constants.typeAlert.warning,
      message: "",
      isShowDialogCustomer: false,
      showDialogSave: false,
      showDialogLock: false,
      textCancel: this.$t('cancel'),
      textAccept: '',
      checkSelected: null,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      breadcrumbs: [
        {
          text: 'list_customers',
          disabled: false,
          isActive: false,
          href: routePaths.CUSTOMERS
        },
        {
          text: 'customer_profile',
          disabled: true,
          isActive: true,
          tooltip: false
        },
      ],
      checkRoleDetail: null
    };
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
    checkRoleBtn() {
      return this.currentRole.includes("ROLE_ADMIN") || this.currentRole.includes("ROLE_ROOT")
    },
    checkRoleLock() {
      return this.currentRole.includes("ROLE_ADMIN") || this.currentRole.includes("ROLE_ROOT")
    }
  },
  async created() {
    await this.getDetail();
    if (window.location.pathname && (!this.currentRole.includes("ROLE_ADMIN") && !this.currentRole.includes("ROLE_SALE")) || this.checkRoleDetail === undefined) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
    if (this.checkRoleBtn) {
      this.getShopPricesNjv()
      this.getShopPricesGhtkNormal()
      this.getShopPricesGhtkExpress()
      this.getShopPricesGhtkOver()
    }
  },
  methods: {
    ...mapActions("layout", ["setBreadcrumbs"]),
    async getDetail() {
      this.showLoading = true;
      const pars = {
        usersId: this.id,
      };
      const { status, data } = await UserService.getCustomerInfo(pars);
      this.showLoading = false;
      this.checkRoleDetail = data
      if (status === constants.statusCode.ok && data) {
        this.detail = data;
        this.$refs.showDialogCustomer.getDataRadio(this.detail.saleId)
        if (this.detail.pricesGroupDtos) {
          this.detail.pricesGroupDtos.forEach((it) => {
            if (it.partnerId === constants.partnerGroup.ninjaVan) {
              this.valuePriceNjv = it.priceGroupId
              this.valuePriceNjvName = it.priceGroupName
            } else {
              if (it.serviceId === constants.servicesShipping.standard) {
                this.valuePriceGhtkNor = it.priceGroupId
                this.valuePriceGhtkNorName = it.priceGroupName
              } else if (it.serviceId === constants.servicesShipping.express) {
                this.valuePriceGhtkExp = it.priceGroupId
                this.valuePriceGhtkExpName = it.priceGroupName
              } else {
                this.valuePriceGhtkOvr = it.priceGroupId
                this.valuePriceGhtkOvrName = it.priceGroupName
              }
            }
          })
        }
      }
    },
    // bang gia ninja van
    async getShopPricesNjv() {
      const pars = {
        poId: this.detail.poId,
        partnerId: constants.partnerGroup.ninjaVan,
        serviceId: constants.servicesShipping.normal,
        limit: constants.maximumLimitPerPage
      }
      const { status, data } = await PricesService.getShopPrices(pars);
      if (status === constants.statusCode.ok && data) {
        this.lstPriceNjv = data.data;
      }
    },
    // bang gia ghtk
    async getShopPricesGhtkNormal() {
      const pars = this.bindRequestPars(constants.servicesShipping.standard)
      const { status, data } = await PricesService.getShopPrices(pars);
      if (status === constants.statusCode.ok && data) {
        this.lstPriceGhtkNormal = data.data;
      }
    },
    async getShopPricesGhtkExpress() {
      const pars = this.bindRequestPars(constants.servicesShipping.express)
      const { status, data } = await PricesService.getShopPrices(pars);
      if (status === constants.statusCode.ok && data) {
        this.lstPriceGhtkExpress = data.data;
      }
    },
    async getShopPricesGhtkOver() {
      const pars = this.bindRequestPars(constants.servicesShipping.over)
      const { status, data } = await PricesService.getShopPrices(pars);
      if (status === constants.statusCode.ok && data) {
        this.lstPriceGhtkOver = data.data;
      }
    },
    bindRequestPars(val) {
      const pars = {
        poId: this.detail.poId,
        partnerId: constants.partnerGroup.ghtk,
        serviceId: val,
        limit: constants.maximumLimitPerPage
      }
      return pars;
    },
    async getListPriceShop() {
      const { status, data } = await PricesService.getPriceShop();
      if (status === constants.statusCode.ok && data) {
        this.listPriceGroup = data;
      }
    },
    lockAccount() {
      this.message = this.$t('to_lock_account')
      this.textAccept = this.$t('lock')
      this.showDialogLock = true
    },
    async onAccept() {
      this.showDialogLock = false;
      this.showLoading = true;
      const pars = {
        usersId: this.detail.id
      }
      const { status, data } = await UserService.tunrOff(pars)
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("lock_shop_success"),
        });
        setTimeout(() => {
          this.toggleDialogNoti();
          this.$router.push({
            path: routePaths.CUSTOMERS,
          });
          this.addBreadCrumbs(routePaths.CUSTOMERS);
        }, constants.timeOut);
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    onShowDialogSave() {
      this.showDialogSave = true
    },
    cancelDialog() {
      this.showDialogSave = false;
      this.showDialogLock = false;
    },
    checkSelectPrice() {
      const arrPrice = [];
      if (this.valuePriceNjv) {
        arrPrice.push(this.valuePriceNjv)
      }
      if (this.valuePriceGhtkNor) {
        arrPrice.push(this.valuePriceGhtkNor)
      }
      if (this.valuePriceGhtkExp) {
        arrPrice.push(this.valuePriceGhtkExp)
      }
      if (this.valuePriceGhtkOvr) {
        arrPrice.push(this.valuePriceGhtkOvr)
      }
      return arrPrice
    },
    submitForm() {
      const arrPrice = this.checkSelectPrice()
      if (!arrPrice.length) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.warning,
          msg: this.$t("choice_one_price"),
        });
        setTimeout(() => {
          this.toggleDialogNoti()
        }, 1500);
      } else {
        this.message = this.$t('to_save_changes')
        this.textAccept = this.$t('save')
        this.showDialogSave = true
      }
    },
    async onSubmit() {
      this.showDialogSave = false;
      this.showLoading = true;
      const arrPrice = this.checkSelectPrice()
      const pars = {
        custId: this.detail.id,
        pricesGroupIds: arrPrice.length ? arrPrice : undefined
      };
      if (this.detail.saleId) {
        pars["saleId"] = this.detail.saleId;
      }
      const { status, data } = await UserService.updateShopPriceSale(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("success_saved_info"),
        });
        setTimeout(() => {
          this.toggleDialogNoti();
          this.$router.push({
            path: routePaths.CUSTOMERS,
          });
          this.addBreadCrumbs(routePaths.CUSTOMERS);
        }, constants.timeOut);
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    // show dialog customer
    showDialogCustomer() {
      this.isShowDialogCustomer = true;
      this.$refs.showDialogCustomer.searchEmployeePostoffice()
    },
    closeDialogCustomer() {
      this.isShowDialogCustomer = false
    },
    selectEmployee(val, listEmployee) {
      listEmployee.forEach((item) => {
        if (val === item.id) {
          this.detail.saleName = item.name;
          this.detail.salePhone = item.phone;
          this.detail.saleEmail = item.email;
          this.detail.saleId = item.id
          this.detail.saleCode = item.code
        }
      })
    },
    getTextCode(item) {
      if (item.saleCode === "" || !item.saleCode) {
        return ""
      }
      return `(${item.saleCode})`
    },
    getTextCustomerType(item) {
      if (item === constants.customerType.personal) {
        return this.$t('personal')
      } else {
        return this.$t('shop')
      }
    },
    addBreadCrumbs(pathActive) {
      const breadCrumbs =
        DataBreadCrumbUtils.getBreadCrumbByPathActive(pathActive);
      this.setBreadcrumbs(breadCrumbs);
    },
    toggleDialogNoti(
      pars = { state: false, type: constants.typeAlert.warning, msg: "" }
    ) {
      this.showNoti = pars.state;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    formatDate(date) {
      return DateTimeUtils.formatDateTime(date, constants.formatDate);
    },
    getTextTruncate(val, size) {
      if (val.length > size) {
        return val.substring(0, size) + "...";
      } else {
        return val;
      }
    }
  },
};
</script>

<style src="@/styles/postOffice.scss" lang="scss">
</style>
